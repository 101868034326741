import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import 'firebase/compat/analytics'
//import  Firebaseui  from 'firebaseui'

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBQac5PZqqfD1BO21iRLUASoemsJGBxBH0",
  authDomain: "spried-bb41e.firebaseapp.com",
  projectId: "spried-bb41e",
  storageBucket: "spried-bb41e.appspot.com",
  messagingSenderId: "559502217297",
  appId: "1:559502217297:web:d04d1db49ddbb3cec6a870",
  measurementId: "G-R5X1NVP6ER"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.storage();

const db = firebase.firestore();

export default firebase;
export { db };