import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {getFirebase, isLoaded } from 'react-redux-firebase'


import {  GoogleReCaptchaProvider,  GoogleReCaptcha} from 'react-google-recaptcha-v3';

import {baseURL} from "../store/api/Access"

import axios from 'axios'

import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { firestoreConnect } from  'react-redux-firebase'
import { useFirestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { firestore } from 'firebase/compat'


import  firebase  from 'firebase/compat'

import  ThankYou  from './ThankYou'
import  Loading  from './Loading'
import  Page404  from './Page404'

import {Card,Container ,CardMedia,CardContent,CardActions,Collapse} from '@material-ui/core';

import {
  Box,
  Tabs,
  Alert,
  Tab,IconButton,
  CardHeader,
  Select,
  Grid,
  TextField, 
  MenuItem,
  Switch,
Checkbox,
Divider,
FormControlLabel,TextareaAutosize,
Typography,
Button
    } from '@mui/material';



const defaultInputs = [
  {
    name:"Review",
    id:"review",
    type:"textarea",
    required:true
  },
  {
    name:"Suggestions",
    id:"suggestions",
    type:"textarea",
    required:false
  }
]


const useStyles = makeStyles((theme) => ({
  root: {
    margin: '0 40px',
    marginTop: '80px',
  },
  CardActions:{
    display:"flex",
    padding: "16px"
  },
  SubmitBox:{
    flexGrow:1,
  },
  SubmitButton:{
    background:"#1B263B !important",
    color:"#fff !important",
    padding:"10px 20px !important",
    "&:hover":{
      background:"#888  !important",
    }
  },
  pp:{
    textAlign:"right",
    color:"rgba(0, 0, 0, 0.54)"
  },
  ppLinks:{
    color:"rgba(0, 0, 0, 0.54)",
    textDecoration: "none",

  }
}));


function View({Service,Services, match, FeedbackService}) { 
  const classes = useStyles();
  const [values, setValues] = useState({type:"Review"});
  const [Status, setStatus] = useState({loading:false, thankyou:false, error:null,timeoutTimer:null});
  const [SelectedService, setSelectedService] = useState(null);
  
  if(match.params.id && !SelectedService)
  {
    setSelectedService(match.params.id)
    setValues({...values,service: match.params.id});
  }
  
  useEffect(() => {
    document.title = 'SPRIED - Feedback'
  }, [])


  useFirestoreConnect([
    {  collection: 'Services', storeAs: 'Service', doc:SelectedService}
  ])

  
  const handleTimeout = () => {
    if(Status.timeoutTimer)
      setStatus({Loading:false, thankyou:false, error:"Timeouted",timeoutTimer:null});
};

  const handleServiceChange  = (event) => {
    setSelectedService(event.target.value);
    setValues({...values,service: event.target.value});
  }

  const handleChange  = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  }
  
  const handleCheck = (event)=>  {
    setValues({
      ...values,
      [event.target.name]: event.target.checked
    });
  }

  const handleVerify=token => {
    setValues({
    ...values,
    token: token
  });
  }

  const handleSubmit  = (event) => { 
    event.preventDefault();

    if(!SelectedService)
    {
      setStatus({loading:false, thankyou:false, error:"Please select sevice.",timeoutTimer:null})
      return
    }
    
    
    var detialsArray = defaultInputs

    if(FeedbackService && FeedbackService.feedback)
      detialsArray = FeedbackService.feedback

    if(values.type == "Bug" &&  FeedbackService && FeedbackService.bug)
      detialsArray = FeedbackService.bug
      
    if(values.type == "Review" &&  Service && Service.feedback)
      detialsArray = Service.feedback

    if(values.type == "Bug" &&  Service && Service.bug)
      detialsArray = Service.bug

    

    var failed = false
    detialsArray.forEach(element => {
      if(element.required &&  (!values[element.id]  || values[element.id] == "" || values[element.id] == false))
      {
        failed = true
      }
    });

    if(failed)
    {
      setStatus({loading:false, thankyou:false, error:"Must fill in all required.",timeoutTimer:null})
      return
    }







    const timeoutTimer = setTimeout(handleTimeout, 30000)
    setStatus({loading:true, thankyou:false, error:null,timeoutTimer:timeoutTimer})

    
    axios.post(baseURL+'/feedback',values).then(Result => {
      if(Status.timeoutTimer)
          clearInterval(Status.timeoutTimer);
      
    console.log(Result)

      if(Result.data.success)
      {
        setStatus({loading:false, thankyou:true, error:null,timeoutTimer:null})
        setValues( {type:"Review"} );
        return
      }

      setStatus({Loading:false, thankyou:true, error:"Could not send",timeoutTimer:null});
      
  }).catch(err => {
    console.log(err)
      if(Status.timeoutTimer)
          clearInterval(Status.timeoutTimer);
          setStatus({loading:false, thankyou:false, error:"Sending error",timeoutTimer:null})
        });   
  }


  


  
  if((match.params.id && !isLoaded(Service)) || Status.loading || !isLoaded(FeedbackService) ||  !isLoaded(Services))
  {
    return (<Loading />)
  }

  if(match.params.id && isLoaded(Service) && !Service)
  {
    return (<Page404 />)
  }
  
  if(Status.thankyou)
  {
    return (<ThankYou />)
  }





  var detialsArray = defaultInputs

  if(FeedbackService && FeedbackService.feedback)
    detialsArray = FeedbackService.feedback

  if(values.type == "Bug" &&  FeedbackService && FeedbackService.bug)
    detialsArray = FeedbackService.bug
    
  if(values.type == "Review" &&  Service && Service.feedback)
  detialsArray = Service.feedback

if(values.type == "Bug" &&  Service && Service.bug)
  detialsArray = Service.bug






  return (
        <Card style={{ minWidth: 275, marginBottom:"100px" }}>
        <CardHeader
          title="Feedback"
          subheader="Provide feedback or report a bug to improve this service."
        />
        <CardContent>
        
        {Status.error ? <Alert severity="error">Error — {Status.error}</Alert> : null}
        
        <h2>Contacts Details</h2>
        <p>Adding this could help to contact you for more information.</p>
        <TextField fullWidth onChange={handleChange} value={values.name ? values.name : null} label="Name" name="name" variant="standard" />
        <TextField fullWidth onChange={handleChange} value={values.email ? values.email : null} label="Email" name="email" variant="standard" />




        {match && match.params && match.params.id  ? null :<>
            <Divider />
            <h2>service</h2>

            <p>Select service below (required)</p>
            <Select
              variant="standard"
              onChange={handleServiceChange}
              value={SelectedService}
              fullWidth
            >
              {Services && Services.map((item) =>(
                <MenuItem value={item.id}>{item.name}</MenuItem>
              ))}
            </Select>
          </>
        }

          <p> Review Type</p>
          <Select
              variant="standard"
              onChange={handleChange}
              fullWidth
              value={values.type ? values.type : "Review"}
              name="type"
            >
              <MenuItem value="Bug">Report Bug</MenuItem>
              <MenuItem value="Review">Review</MenuItem>
            </Select>



        <Divider />
        <h2>Details</h2>
        {SelectedService && !isLoaded(Service) ? <Box>Loading</Box> :<Box>

        { isLoaded(Service) && detialsArray.map((element) => {

          switch (element.type) {
            case "switch":
              return(<FormControlLabel control={<Switch onChange={handleCheck}  required={element.required ? true :false }  name={element.id} checked={values[element.id] ? true : element.default ? element.default:false}/>} label={element.name} />)
            
              case "checkbox":
                return(<FormControlLabel control={<Checkbox  onChange={handleCheck}  required={element.required ? true :false } name={element.id} checked={values[element.id] ? true : element.default ? element.default:false} />} label={element.name} />)

                case "select":
                  return(<Box>
                    <p style={{ marginBottom:5 }}> {element.name + (element.required ? " (required)" :"")} </p>
                    <Select
                          variant="standard"
                          //labelId="demo-simple-select-label"
                          //id="demo-simple-select"
                          required={element.required ? true :false }
                          name={element.id} 
                          onChange={handleChange}
                          value={values[element.id] ? values[element.id] : element.default ? element.default:null}
                          fullWidth
                        >
                          {element.items && element.items.map((item) =>(
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          ))}
                        </Select>
                        </Box>)                  
  
            case "html":
                return(<Box>{element.html}</Box>)
        
        
            case "textarea":
                return(<Box>
                    <p style={{ marginBottom:5 }}> {element.name  + ((element.required ? " (required)" :""))} </p>
                    <TextareaAutosize
                    //aria-label="minimum height"
                    required={element.required ? true :false }
                    minRows={3}
                    name={element.id} 
                    onChange={handleChange}
                    //placeholder="Minimum 3 rows"
                    style={{ width: "100%" , minWidth: "100%", minHeight: "50px" , maxWidth: "100%" }}
                    fullWidth 
                    value={values[element.id]} 
                     label={element.name + (element.required ? " (required)" :"")}
                      variant="standard" 
                  /></Box>)
                    
            case "number":
            case "date":
            case "text":
            case "color":
                return(<TextField required={element.required ? true :false } fullWidth type={element.type}  name={element.id} value={values[element.id] ? values[element.id] : element.default ? element.default:null} onChange={handleChange} label={element.name + (element.required ? " (required)" :"")} variant="standard" />)
          }

          return null

        })

        }</Box>}



       {values && !values.token ? <GoogleReCaptcha onVerify={handleVerify} /> : null}


        </CardContent>
        <CardActions className={classes.CardActions}>
        <Box className={classes.SubmitBox}>

          <Button onClick={handleSubmit} className={classes.SubmitButton}>Submit</Button>
          </Box>
          <Box className={classes.pp}>
            <NavLink  className={classes.ppLinks} target="_blank" to="/privacy">Privacy Policy</NavLink>
            <br/>
            <NavLink className={classes.ppLinks} target="_blank" to="/terms">Terms of Service</NavLink>

          </Box>
        </CardActions>
      </Card>
  );
}



const mapStateToProps = (state, Props) => {
  return {
    Service: state.firestore.ordered.Service &&  state.firestore.ordered.Service[0] ? state.firestore.ordered.Service[0] : null,
    Services: state.firestore.ordered.Services,
    FeedbackService: state.firestore.ordered.FeedbackService &&  state.firestore.ordered.FeedbackService[0] ? state.firestore.ordered.FeedbackService[0] : null,
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect(props =>{
    return [
      { collection: 'Services', storeAs: 'Services'},
      { collection: 'Feedback', storeAs: 'FeedbackService', doc:"Services"},
    ]
})
)(View);



